@import "../../base";

.sidebar {
  background: $black-secondary;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 90vh;
  padding-top: 2rem;
  transition: transform 0.2s ease-in;

  position: sticky;
  top: 10vh;
  left: 0;

  li {
    display: flex;
    align-items: center;

    padding: 0.6rem 1.5rem;
    margin: 0.2rem 0;
    cursor: pointer;

    span {
      margin-left: 1rem;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.4px;
    }

    &:hover {
      background-color: $border-color;
    }
  }
  hr {
    background-color: $border-color;
  }

  &.open {
    transform: translateX(0);
  }
}

@media (max-width: $breakpoint-medium) {
  .sidebar {
    width: 90px;
    li {
      justify-content: center;
    }
    span {
      display: none;
    }
  }
}

@media (max-width: $breakpoint-small) {
  .sidebar {
    transform: translate(-100%);
    position: fixed;
    z-index: 999;
  }
}
