@import "../../base";

.videoMetaData {
  &__top {
    span {
      cursor: pointer;
    }
  }
  &__channel {
    border-top: 0.2px solid $border-color;
    border-bottom: 0.2px solid $border-color;
    img {
      width: 50px;
      height: 50px;
    }
    button {
      background-color: red;
      color: #fff;
      border-radius: 0;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      &.btn-gray {
        background-color: gray;
      }
      &:hover {
        color: #fff;
      }
      &:focus {
        border: none;
      }
    }
  }
  &__description {
    font-size: 0.9rem;
    white-space: pre-line;
    border-bottom: 0.2px solid $border-color;
    .showMoreText {
      text-decoration: none;
      display: block;
      margin: 1rem 0;
      color: #fff;
      font-weight: 500;
    }
  }
}
