.comments_form {
  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  input {
    background: transparent;
    border: none;
    border-bottom: 2px solid #353946;
    color: #fff;
    &:focus {
      outline: none;
    }
  }
  button {
    background-color: #353946;
    color: #fff;
    letter-spacing: 0.5px;
    &:focus {
      border: none;
      outline: none;
    }
  }
}
