@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;letter-spacing:.1px}::-webkit-scrollbar{width:0px}.header{display:flex;justify-content:space-between;align-items:center;padding:1rem 3rem;background-color:#16181b;height:10vh;width:100%;position:fixed;top:0;z-index:999}.header__menu{display:none}.header__logo{width:50px;height:50px;object-fit:contain;display:block}.header form{flex:.6 1;display:flex;padding:.1rem;margin:.1rem;border-radius:3px;border:1.2px solid;background-color:#121417}.header form input{width:100%;border:none;font-weight:500;background:transparent;padding:.3rem;color:#b1bdb4}.header form input:focus{outline:none}.header form button{padding:0 1.25rem;color:#b1bdb4;background:transparent;border:none}.header form button:focus{border:none}.header__icons{flex:.15 1;display:flex;justify-content:space-around;align-items:center}.header__icons img{border-radius:50%;width:40px;object-fit:contain;margin-left:5px}@media(max-width: 520px){.header{padding:1rem}.header form{flex:1 1}.header__menu{display:block}.header__logo{display:none}.header__icons>*:not(img){display:none}}
*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;letter-spacing:.1px}::-webkit-scrollbar{width:0px}.sidebar{background:#121417;display:flex;flex-direction:column;width:250px;height:90vh;padding-top:2rem;transition:-webkit-transform .2s ease-in;transition:transform .2s ease-in;transition:transform .2s ease-in, -webkit-transform .2s ease-in;position:-webkit-sticky;position:sticky;top:10vh;left:0}.sidebar li{display:flex;align-items:center;padding:.6rem 1.5rem;margin:.2rem 0;cursor:pointer}.sidebar li span{margin-left:1rem;font-size:14px;font-weight:500;letter-spacing:.4px}.sidebar li:hover{background-color:#4c4c4c}.sidebar hr{background-color:#4c4c4c}.sidebar.open{-webkit-transform:translateX(0);transform:translateX(0)}@media(max-width: 1224px){.sidebar{width:90px}.sidebar li{justify-content:center}.sidebar span{display:none}}@media(max-width: 520px){.sidebar{-webkit-transform:translate(-100%);transform:translate(-100%);position:fixed;z-index:999}}
*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;letter-spacing:.1px}::-webkit-scrollbar{width:0px}.categoriesBar{padding:.5rem 0;font-size:.8rem;display:flex;overflow-x:scroll;scrollbar-width:none}.categoriesBar::-webkit-scrollbar{width:0px}.categoriesBar span{margin-right:1rem;padding:.5rem;white-space:nowrap;border:1.5px solid #b1bdb4;border-radius:999px}.categoriesBar span:hover{background-color:#374a59}.categoriesBar span.active{color:#fff;background-color:#606060;border-color:#4c4c4c}
*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;letter-spacing:.1px}::-webkit-scrollbar{width:0px}.video{margin-bottom:1rem;padding:.7rem;font-weight:500;font-size:.9rem;cursor:pointer}.video__top{margin-bottom:.5rem;position:relative}.video__top .lazy-load-image-background{width:100%}.video__top img{width:100%}.video__top__duration{position:absolute;bottom:.3rem;right:.3rem;padding:.2rem;background:#080808ec;border-radius:3px}.video__bottom{display:flex;flex-direction:row}.video__bottom .left img{width:36px;height:36px;border-radius:50%;margin-right:.5rem;cursor:pointer}.video__bottom .right{display:flex;flex-direction:column}.video__bottom .right .title{align-items:center;margin-bottom:.1rem;overflow:hidden;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;color:#fff}.video__bottom .right .title .video__channel{display:flex;align-items:center;margin:.5rem 0}
*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;letter-spacing:.1px}::-webkit-scrollbar{width:0px}.login{height:100vh;display:grid;place-items:center}.login__container{background-color:#121417;padding:2rem;margin:0 1rem;border-radius:10px;display:flex;flex-direction:column;align-items:center}.login__container button{padding:.5rem;border:none;border-radius:5px;margin-bottom:1rem}.login__container button:focus{outline:none}.login img{width:130px;height:130px;object-fit:contain}
.app__container{display:flex;margin-top:10vh}
.comments_form img{width:50px;height:50px;object-fit:contain}.comments_form input{background:transparent;border:none;border-bottom:2px solid #353946;color:#fff}.comments_form input:focus{outline:none}.comments_form button{background-color:#353946;color:#fff;letter-spacing:.5px}.comments_form button:focus{border:none;outline:none}
.comment{font-size:.9rem;border-bottom:2px solid #353946}.comment img{width:50px;height:50px}.comment__header{color:#fff}
*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;letter-spacing:.1px}::-webkit-scrollbar{width:0px}.videoHorizontal{border-bottom:.3px solid #4c4c4c;cursor:pointer}.videoHorizontal__left{position:relative;text-align:center}.videoHorizontal__thumbnail{width:100%}.videoHorizontal__thumbnail-channel{width:50%;border-radius:50%}.videoHorizontal__thumbnail-wrapper{width:100%}.videoHorizontal__duration{position:absolute;bottom:.5rem;right:1.2rem;font-size:.9rem;padding:.2rem;background:#080808ec;border-radius:3px}.videoHorizontal__details{font-size:.9rem}.videoHorizontal__title{font-size:1rem;letter-spacing:.3px;overflow:hidden;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical}.videoHorizontal__desc{overflow:hidden;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical}.videoHorizontal__channel img{width:36px;height:36px;border-radius:50%;margin-right:.5rem;cursor:pointer}.videoHorizontal__channel p{font-size:.9rem;overflow:hidden;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical}@media(max-width: 520px){.videoHorizontal{font-size:.8rem}.videoHorizontal__details{overflow:hidden;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical}.videoHorizontal__channel{margin:.1rem 0}.videoHorizontal__channel img{display:none}.videoHorizontal__channel p{font-size:.9rem}.videoHorizontal__desc{display:none}.videoHorizontal__title{font-size:1rem;overflow:hidden;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical}}
*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;letter-spacing:.1px}::-webkit-scrollbar{width:0px}.videoMetaData__top span{cursor:pointer}.videoMetaData__channel{border-top:.2px solid #4c4c4c;border-bottom:.2px solid #4c4c4c}.videoMetaData__channel img{width:50px;height:50px}.videoMetaData__channel button{background-color:red;color:#fff;border-radius:0;text-transform:uppercase;letter-spacing:.5px}.videoMetaData__channel button.btn-gray{background-color:gray}.videoMetaData__channel button:hover{color:#fff}.videoMetaData__channel button:focus{border:none}.videoMetaData__description{font-size:.9rem;white-space:pre-line;border-bottom:.2px solid #4c4c4c}.videoMetaData__description .showMoreText{text-decoration:none;display:block;margin:1rem 0;color:#fff;font-weight:500}
.watchScreen__player{height:60vh;background-color:#353946;width:100%;margin-bottom:2rem}
*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;letter-spacing:.1px}::-webkit-scrollbar{width:0px}.channelHeader{border-bottom:1px solid #4c4c4c}.channelHeader__left img{width:80px;height:80px;border-radius:50%}.channelHeader button{text-transform:uppercase;background-color:red;color:#fff;font-size:1rem;padding:.4rem;border:none}@media(max-width: 1224px){.channelHeader{padding:1.1rem 0}.channelHeader img{width:50px;height:50px}.channelHeader h3{font-size:1rem;margin:0}}
*{margin:0;padding:0;box-sizing:border-box}body{background:#16181b;background-attachment:fixed;color:#b1bdb4;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;letter-spacing:.1px}::-webkit-scrollbar{width:0px}
