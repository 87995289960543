.comment {
  font-size: 0.9rem;
  border-bottom: 2px solid #353946;
  img {
    width: 50px;
    height: 50px;
  }
  &__header {
    color: #fff;
  }
}
